<template>
    <div class="container">
      <!-- Header -->
      <header>
        <div class="header">
          <span> DIGITALSIGN </span>
          <!-- <button v-on:mouseover="mouseOver" v-bind:class="{on: active, off: !active}"> LOGIN </button> -->
          <button id="btn-login" > LOGIN </button>
        </div>
      </header>
      <div class="login">
        <router-view />
      </div>
      <!-- Footer -->
      <footer>
        <span>
          Digital Sign @2021
        </span>
      </footer>
    </div>
</template>

<script>

export default {
  name: "Login",
  components: {
  },
  // data() {
  //   return {
  //     active: false
  //   }
  // },
  // methods: {
  //   mouseOver: function() {
  //     this.active = !this.active
  //   }
  // }
};
</script>

<style lang="scss" scoped>

.container {
  //background-color: #4B088A;
  background-image: url('~@/assets/images/bk.jpg');
  background-size: cover;
  height: 100vh;
  margin: 0;
  display: grid;
  grid-auto-rows: 100px 1fr 50px;
  header {
    @include flex(center, center);
    .header {
      width: 40%;
      @include flex(space-between, center);
      span {
        color: white;
      }
      #btn-login {
        background-color: orange;
        border-radius: 7px;
        width: 100px;
        height: 50px;
        //padding-right: 5px;
        &:hover {
          background-color: #B43104 !important;
        }
      }
    }
  }

  footer {
    span {
      color: white;
      padding-left: 300px;
    }
  }
}
// .on {
//   background-color: orange;
//   border-radius: 7px;
//   width: 100px;
//   height: 50px;
// }
// .off {
//   background-color: #B43104;
//   border-radius: 7px;
//   width: 100px;
//   height: 50px;
// }
</style>
